<template>
  <div class="complaint-page">
    <div class="content">
      <h1 class="header">版权说明</h1>
      <div class="input-line">
        <p><b>1、本网站申明：</b></p>
        <br />
        <p>
          本网站是一个网盘搜索引擎网站，全站链接通过蜘蛛程序收集自百度网盘公开链接，以非人工方式自动生成页面。本网站本身不储存、复制、传播、控制编辑任何网盘资源文件，不提供下载服务，仅做索引并提供搜索功能。本站所有功能服务仅供学习交流，如果你喜欢，请购买正版。
        </p>
        <br />
        <p><b>2、相关法律：避风港原则</b></p>
        <br />
        <p>
          《条例》第14条规定：“对提供信息存储空间或者提供搜索、链接服务的网络服务提供者，权利人认为其服务所涉及的作品、表演、录音录像制品，侵犯自己的信息网络传播权或者被删除、改变了自己的权利管理电子信息的，可以向该网络服务提供者提交书面通知，要求网络服务提供者删除该作品、表演、录音录像制品，或者断开与该作品、表演、录音录像制品的链接。
        </p>
        <br />
        <p>通知书应当包含下列内容：</p>

        <p>（一）权利人的姓名（名称）、联系方式和地址；</p>

        <p>
          （二）要求删除或者断开链接的侵权作品、表演、录音录像制品的名称和网络地址；
        </p>

        <p>（三）构成侵权的初步证明材料。　权利人应当对通知书的真实性负责。</p>
        <br />
        <p><b>3、著作权保护声明</b></p>
        <br />
        <p>具体措施和步骤如下：</p>
        <p>权利请求</p>
        <p>任何个人或单位如果同时符合以下两个条件：</p>
        <p>1、是某一作品的著作权人和/或依法可以行使信息网络传播权的权利人；</p>
        <p>
          2、本网站通过蜘蛛程序收集自百度网盘公开分享链接侵犯了上述作品信息的权利。
        </p>
        <p>上述个人或单位可以以电子邮件的通讯方式向本网站提交权利通知。</p>
        <p>根据相关法律规定，权利通知必须包含如下资料：</p>
        <p>
          1、请提供具体的联络信息，包括姓名、身份证或护照复印件（对自然人）、营业执照复印件（对单位）、通信地址、电话号码、传真和电子邮件。
        </p>
        <p>2、请完整、准确地指明涉嫌侵权作品的名称和登载该作品的网页的地址。</p>
        <p>
          3、请提供构成侵权的初步证明材料，谨此提示如以下材料可能会构成初步证明：
        </p>
        <p>
          a.
          对涉嫌侵权作品拥有著作权和／或依法可以行使信息网络传播权的权属证明；
        </p>
        <p>b. 对涉嫌侵权作品侵权事实的举证。</p>
        <p>
          4、请您在该权利通知落款处亲笔签名，如果您是依法成立的机构或组织，请您加盖公章。
        </p>
        <br />
        <p>
          请注意：如果权利通知的陈述失实，权利通知提交者将承担由此造成的全部法律责任（包括但不限于赔偿各种费用及律师费）。
        </p>
        <br />
        <p><b>4、投诉邮箱</b></p>
        <br />
        <p>cixin64462800@163.com</p>
        <br />
        <p><b>5、本网站义务</b></p>
        <br />
        <p>
          本网站作为搜索引擎，将严格拥护著作权拥有者的权利，如果百度网盘方未能及时受理你的投诉建议，烦请您根据第三条提供有效书面证明给我们，我们经过确认后认为侵权，将立即屏蔽删除该索引内容或断开该文件链接，以免对您造成损失。
        </p>
        <br />
        <p><b>6、建议说明</b></p>
        <br />
        <p>
          由于网站内容为机器自动收集，本网站毫无侵权的主观意愿。我们一直在致力于避免侵权行为，除了积极配合权利方的要求，另外还定期自我巡查，主动删除屏蔽认为有涉及版权的链接。但由于数据量大，系统屏蔽机制结合人工审查都难免存在疏漏。这里建议维权者也能本着实事求是的态度，确保内容确实是属于您的，避免因为同名、空内容等对本网站错误指责，特此建议。
        </p>
      </div>
      <div class="menu">
        <ul>
          <li><router-link to="/main/about/policy">免责声明</router-link></li>
          <li><router-link to="/main/about/agree">用户协议</router-link></li>
          <li><router-link to="/main/about/version">版权说明</router-link></li>
          <li><router-link to="/main/about/safe">净网声明</router-link></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_var.scss";
.complaint-page {
  min-height: 100%;
  background-color: #f8f8f8;
}
.content {
  margin: 20px auto;
  max-width: 800px;
  padding: 20px 40px;
  border-radius: 4px;
  border: 1px solid #ddd;
  background-color: #fff;
  font-size: 15px;
  line-height: 1.6;
  text-align: center;
  word-break: break-all;
  .complaint-image {
    width: 60px;
    margin-bottom: 10px;
  }
  .header {
    margin-bottom: 10px;
  }
  .input-line {
    text-align: left;
    margin-bottom: 15px;
  }
  .menu {
    width: 100%;
    border-top: 1px solid $theme-color;
    height: 40px;
    margin-top: 50px;
    ul {
      margin: 0px;
      list-style: none;
      li {
        float: right;
        margin-left: 30px;
        a {
          color: $theme-color;
        }
      }
    }
  }
}
@media (max-width: 700px) {
  .content {
    padding: 20px;
  }
}
</style>
